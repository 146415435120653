@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.tryNewOuterWrapper{
    @include respond(tablet) {
        display: none;
    }
    background: 
      url(../../../assets/images/TryNew.svg) center center no-repeat,
      linear-gradient(88deg, rgba(88, 83, 213, 0.25) 0%, rgba(131, 127, 223, 0.00) 51.56%, rgba(88, 83, 213, 0.25) 98.96%);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center bottom;
.wrapper{
    @include fwcontainer;
    @include respond(tablet) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    
    .bannerOuterWrapper{
      @include respond(tablet) {
        height: 243px;
        padding: 12px;
      }
      padding: 24px 4px 24px 0px;
      display: flex;
      flex-direction: column;
      .bannerTitle{
        width: 360px;
        white-space: pre-line;
        height: 48px;
        color: var(--alias-grayscale-header, #14142B);
        font-family: 'Avenir';
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        .bannerSIQText{
            color: var(--StudyIQ-Primary, #423DCF);
            font-family: 'Avenir';
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 150%;
          @include respond(tablet) {
            color: var(--global-colors-yellow-100, #FFF8E9);
            font-family: 'Avenir';
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 150%;
          }
        }
        @include respond(tablet) {
          width: 304px;         
          height: 60px;
          color: #FFDF9A;
          font-family: 'Avenir';
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 150%; 
        }
      }
      .bannerInnerText{
        width: 570px;
        white-space: pre-line;
        margin-top: 4px;
        color: var(--alias-grayscale-body, #4E4B66);
        font-family: 'Avenir';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; 
        @include respond(tablet) {
          width: 281px;
          color: var(--alias-grayscale-input, #EFF0F6);
          font-family: 'Avenir';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }
      .bannerInnerbuttons{
        display: flex;
        flex-direction: row;
        gap:12px;
        margin-top: 16px;
        flex-wrap: wrap;
        .bannerStartNow{
            display: flex;
            flex-direction: row;
            .tryNewButtons:hover{
              border: 1px solid var(--alias-grayscale-line, #423DCF);
            }
            .tryNewButtons{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 11px;
                gap:12px;
                border-radius: 12px;
                border: 1px solid var(--alias-grayscale-line, #D9DBE9);
                background: #FFF;
                .tryNewText{
                    margin-right: 12px;
                    color: var(--alias-grayscale-header-weak, #262338);
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: 'Avenir';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 24px; 
                }
                .tryNewRA{
                    margin-right: 8px;
                }

            }
          }
      }
    }
    
  }
  margin-bottom: 64px;
    @include respond(tablet) {
      margin-bottom: 28px;
    }
}